<template>
<div id="app">
        <CCard>
          <!--CCardHeader>
            Tabs
            <div class="card-header-actions">
              <a
                href="https://coreui.io/vue/docs/components/tabs"
                class="card-header-action"
                rel="noreferrer noopener"
                target="_blank"
              >
                <small class="text-muted">docs</small>
              </a>
            </div>
          </CCardHeader-->
          <CCardBody>
            <CTabs>
               <CTab title="Account" :active="firstActiveTab !== false"> <br/>
                <account />

                </CTab>
              <CTab title="Wallet"><br/>

                <wallet />
              </CTab>
              <CTab title="Transactions"><br/>
                <transaction />
              </CTab>
              <CTab title="Points"><br/>
                <points />
              </CTab>
              <CTab title="Notification"><br/>
                <notification />
              </CTab>
              <CTab title="Referral"><br/>
                <referral />
              </CTab>
              <CTab title="Earmark" :active="earmarkActiveTab !== false"><br/>
                <earmark />
              </CTab>
            </CTabs>
          </CCardBody>
        </CCard>


</div>
</template>

<script>
import AuthService from '@/api/AuthService.js';
import Account from './details/account';
import Wallet from './details/wallet_v2';
import Transaction from './details/transaction';
import Points from './details/points';
import Notification from './details/notification';
import Referral from './details/referral';
import Earmark from './details/earmark';

export default {
    name: 'detail',
    components: {
      'account': Account,
      'wallet': Wallet,
      'transaction' : Transaction,
      'points' : Points,
      'notification' : Notification,
      'referral' : Referral,
      'earmark' : Earmark
    },
    data () {
      return {
        firstActiveTab: true,
        earmarkActiveTab: false,
        idleVue_onIdle:'',
      }
    },
    directives: {
    },
    created () {
      if(this.$route.query.hasOwnProperty('back')) {
        this.firstActiveTab=false;
        this.earmarkActiveTab=true;
      } else {
        this.firstActiveTab = true;
        this.earmarkActiveTab = false;
      }
    },
    computed: {
    },
    methods: {
    },
 };

</script>
