<template>
       <CCardBody>      
         <CRow>
              <CCol md="7">
                    <h4></h4>
              </CCol>
              <CCol md="5">
                   <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />                    
              </CCol>             
          </CRow>

        
          <div class="table-responsive table">
            <b-table striped hover  :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" sort-by="createDate" :sort-desc="true" >

              <template v-slot:cell(messageRead)="{ item }"> 
                    {{ item.messageRead | readStatus }}
              </template>
              
              <template v-slot:cell(createDate)="{ item }"> 
                    {{ item.createDate | dateWithTime }}
              </template>       

            </b-table>
          </div>

          <div>
            <b-row>
              <b-col sm="3">
                 <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
              </b-col>       
              <b-col sm="9">
                 <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
                 </b-form-select>
              </b-col>
            </b-row>      
          </div>

        </CCardBody>
    
</template>

<script>
import AuthService from '@/api/AuthService.js';
import AccountService from '@/api/AccountService.js';

 export default {
    name: "points",
    data() {
      return {
        items:[],             
        fields: [
          {            
            key: 'title',
            label : 'Title',
            sortable: true
          },
          {            
            key: 'message',
            label : 'Preview Message',
            sortable: true
          },
          {           
            key: 'longMessage',
            label : 'Long Message',
            sortable: true
          },          
          {
            key: 'messageRead',
            label : 'Read',
            sortable: true
          },         
          {
            key: 'createDate',
            label : 'Created Date',
            sortable: true
          }
          
          ],       
        currentPage: 1,
        perPage: 10,
        filter: null,
      };
    },

    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },

        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)            
        },

        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        },
        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },
        readStatus(value){
           if(value) return "Yes"
           else return "No"                   
        },


      },

    created () { 
       this.getNotifications(this.$route.params.id);      
    },
    methods: {      

      getNotifications: function(id) {
        AccountService.getNotifications(id).then(resp => {   //console.log(resp);        
            this.items = resp; 
        }, error => {
          this.loading = false;
        });
      }
    }

 }; 

</script>

<style>
    .table{overflow-y:scroll; }
</style>