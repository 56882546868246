<template>
       <CCardBody>
         <CRow>
              <CCol col="12" class="text-left">                   
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol> 
          </CRow>      
         <CRow>
              <CCol md="1">                    
                    Status
              </CCol>
              <CCol md="5">                    
                    <b-form-select @change="onChangeStatus" v-model="status" :options="optStatus"></b-form-select>
              </CCol>
              <CCol md="6">
                   <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />                    
              </CCol>             
          </CRow>
        
          <div class="table-responsive">
            <b-table striped hover  :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" sort-by="transactionCompletedTime" :sort-desc="true" >

              <template v-slot:cell(transactionCompletedTime)="{ item }"> 
                    {{ item.transactionCompletedTime | dateWithTime }}
              </template>
              
              <template v-slot:cell(pointExpiryDateTime)="{ item }"> 
                    {{ item.pointExpiryDateTime | dateWithTime }}
              </template> 

               <template v-slot:cell(lastUpdate)="{ item }"> 
                    {{ item.lastUpdate | dateWithTime }}
              </template> 

              <template v-slot:cell(actions)="{ item }">            

                <b-dropdown variant="info" text="Actions" size="md">             
                  
                  <router-link to="#" exact v-on:click.native="actCancelUserPoint(item.id)" tag="b-dropdown-item" > Cancel </router-link>
                  
                </b-dropdown> 

            </template>      

            </b-table>
          </div>

          <div>
            <b-row>
              <b-col sm="3">
                 <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
              </b-col>       
              <b-col sm="9">
                 <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
                 </b-form-select>
              </b-col>
            </b-row>      
          </div>

        </CCardBody>
    
</template>

<script>
import AuthService from '@/api/AuthService.js';

 export default {
    name: "points",
    data() {
      return {
        items:[],             
        fields: [
          {
            name:'completedTime',
            key: 'transactionCompletedTime',
            label : 'Completed Time',
            sortable: true
          },
          {           
            key: 'movementRef',
            label : 'Transaction ID',
            sortable: true
          },          
          {
            key: 'promotionCode',
            label : 'Promo Code',
            sortable: true
          },
          {
            key: 'promoType',
            label : 'Promo Type',
            sortable: true
          },        
          {
            key: 'provider',
            label : 'Provider',
            sortable: true
          },
          {
            key: 'provider',
            label : 'Provider',
            sortable: true
          },   
          {
            key: 'pointAmount',
            label : 'No. of Points',
            sortable: true
          }, 
          {
            key: 'currentStatus',
            label : 'Status',
            sortable: true
          },          
          {
            key: 'pointExpiryDateTime',
            label : 'Expired Time',
            sortable: true
          },
          {
            key: 'lastUpdate',
            label : 'Last Updated ',
            sortable: true
          },
          {            
            key: 'actions',
            label: 'Actions'         
          }    
          ],       
        currentPage: 1,
        perPage: 10,
        filter: null,
        status:'ALL',
        optStatus:['COMPLETED','CONSUMED','CANCELED','EXPIRED','ALL'], 
        seen:false,
        msg :'',
        color:''  
      };
    },

    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },

        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)            
        },

        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        },
        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },
      },

    created () { 
       this.retrivePoints();        
    },
    methods: {  

      actCancelUserPoint(id){

        if (confirm('Are you sure you want to Cancel User Point  ? ')) { 
            this.cancelUserPoint(id);
        }

      },

      onChangeStatus(){
        this.retrivePoints();
      }, 

      retrivePoints(){
        let params = {}; 
        params['id']     = this.$route.params.id;
        params['status'] = this.status;
        this.getUserPoints(params);
      },  
      
      cancelUserPoint: function(id) {
         AuthService.cancelUserPoint(id).then(resp => { //console.log(resp);

            if(resp==="S0000"){ 
              this.retrivePoints();
              this.msg   = "Cancel User Point Success!"; 
              this.color = 'success'; this.seen  = true;  

            } else {
              this.msg   =  !resp.data.message ? "Action Cancel Failed!" : resp.data.message; 
              this.color = 'danger'; this.seen  = true;              
            } 
            setTimeout( () =>  this.seen=false , 5000); 
           
        }, error => {
          this.loading = false;
        });
      },      

      getUserPoints: function(params) {
        AuthService.getUserPoints(params).then(resp => {   //console.log(resp);        
            this.items = resp; 
        }, error => {
          this.loading = false;
        });
      },

      getConfigList: function() { 
          AuthService.getConfigList().then(resp => {  //console.log(resp); 
            //this.optStatus = resp.userStatus;
          }, error => {
            console.log('error');    
          });
      }, 

    }

 }; 

</script>