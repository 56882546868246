var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCardBody',[_c('CRow',[_c('CCol',{staticClass:"text-left",attrs:{"col":"12"}},[(_vm.seen)?_c('CAlert',{attrs:{"color":_vm.color,"id":"alert"}},[_vm._v(_vm._s(_vm.msg))]):_vm._e()],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"1"}},[_vm._v(" Status ")]),_c('CCol',{attrs:{"md":"5"}},[_c('b-form-select',{attrs:{"options":_vm.optStatus},on:{"change":_vm.onChangeStatus},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('CCol',{attrs:{"md":"6"}},[_c('CInput',{attrs:{"label":"Search","placeholder":"Type to Search","horizontal":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1),_c('div',{staticClass:"table-responsive"},[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.items,"fields":_vm.fields,"current-page":_vm.currentPage,"per-page":_vm.perPage,"filter":_vm.filter,"sort-by":"transactionCompletedTime","sort-desc":true},scopedSlots:_vm._u([{key:"cell(transactionCompletedTime)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateWithTime")(item.transactionCompletedTime))+" ")]}},{key:"cell(pointExpiryDateTime)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateWithTime")(item.pointExpiryDateTime))+" ")]}},{key:"cell(lastUpdate)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateWithTime")(item.lastUpdate))+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{attrs:{"variant":"info","text":"Actions","size":"md"}},[_c('router-link',{attrs:{"to":"#","exact":"","tag":"b-dropdown-item"},nativeOn:{"click":function($event){return _vm.actCancelUserPoint(item.id)}}},[_vm._v(" Cancel ")])],1)]}}])})],1),_c('div',[_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('b-pagination',{attrs:{"size":"md","total-rows":this.items.length,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-select',{staticStyle:{"width":"100px"},attrs:{"options":[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }