<template>
  <div id="app">
  <div class="accordion" role="tablist">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-1 variant="success" style="text-align:left">Basic Information</b-button>
      </b-card-header>
      <b-collapse id="accordion-1" visible accordion="my-accord" role="tabpanel" v-model="collapseStates[0]">
        <b-card-body>

              <CCol col="12" class="text-left">
                  <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol>

                          <b-container>
                              <b-row>
                                 <b-col sm="6">
                                    <div class="form-group">
                                        <label for="input-small" >User ID :</label>
                                        <b-form-input id="input-small"  v-model="userId" :readonly="true">{{ $route.params.id }}</b-form-input>
                                    </div>
                                </b-col>
                                <b-col sm="6">
                                    <div class="form-group">
                                        <label for="input-small" >Display Name</label>
                                        <b-form-input id="input-small"  v-model="displayName" :readonly="true">{{ detail.displayName }}</b-form-input>
                                    </div>
                                </b-col>
                              </b-row>

                              <b-row>
                                <b-col sm="6">
                                    <!--div class="form-group">
                                        <label for="input-small" >Email Address :</label>
                                        <b-form-input id="emailAddress" name="emailAddress"  v-on:click="actionSave()" v-model="emailAddress" :readonly="true"></b-form-input>
                                    </div-->

                                    <div class="form-group">
                                        <label for="input-small" >Email Address :</label>
                                         <b-input-group>
                                            <b-form-input id="input-small" v-model="emailAddress" :readonly="true"></b-form-input>
                                            <!--b-input-group-append>
                                              <b-button variant="outline-primary" v-on:click="actionVerify()" >Verify</b-button>
                                            </b-input-group-append-->
                                         </b-input-group>
                                     </div>
                                </b-col>
                                <b-col sm="6">
                                    <div class="form-group">
                                        <label for="input-small" >Phone Number</label>
                                        <b-form-input id="input-small"  v-model="phoneNumber" :readonly="true"></b-form-input>
                                    </div>
                                </b-col>
                              </b-row>

                              <b-row>
                                <b-col sm="3">
                                    <div class="form-group">
                                        <label for="input-small" >User Group :</label>
                                        <b-form-input id="input-small"  v-model="userGroup" :readonly="true"></b-form-input>
                                    </div>
                                </b-col>
                                <b-col sm="3">
                                    <div class="form-group">
                                        <label for="input-small" >Group Unique Number</label>
                                        <b-form-input id="input-small"  v-model="groupUniqueNumber" :readonly="true"></b-form-input>
                                    </div>
                                </b-col>
                                <b-col sm="3">
                                    <div class="form-group">
                                        <label for="input-small" >M-Coin Balance</label>
                                        <b-form-input id="input-small"  v-model="mCoinBalance" :readonly="true"></b-form-input>
                                    </div>
                                </b-col>
                                <b-col sm="3">
                                    <div class="form-group">
                                        <label for="input-small" >Preferred Language :</label>
                                        <b-form-input id="input-small"  v-model="preflang" :readonly="true"></b-form-input>
                                    </div>
                                </b-col>

                              </b-row>

                              <b-row>

                                <b-col sm="4">
                                    <div class="form-group">
                                      <label for="input-small" >Current Tier :</label>
                                        <b-form-select v-model="userTier" @change="onchangeTier" :options="optUsrTier" :disabled="!isDisabledTier"></b-form-select>
                                    </div>
                                </b-col>
                                <b-col sm="4">
                                    <div class="form-group">
                                        <label for="input-small" >Previous Tier :</label>
                                        <b-form-input id="input-small" v-model="previousTier" :readonly="true"></b-form-input>
                                    </div>
                                </b-col>
                                <b-col sm="4">
                                    <div class="form-group">
                                        <label for="input-small" >Tier Achieved Date</label>
                                        <b-form-input id="input-small"  v-model="tierAchievedDate" :readonly="true" v-dateSubstr></b-form-input>
                                    </div>
                                </b-col>
                              </b-row>

                              <b-row>
                                <b-col sm="2">
                                    <input type="checkbox" name="cbeditTier"
                                          @change="onModifyClick"
                                          style="width: 20px; height: 20px;"
                                          v-model="cbEditTier"><label for="input-small" >&nbsp;Manual Tier</label>
                                </b-col>
                                <b-col sm="2" v-if="seenBtnSaveTier" >
                                   <b-button variant="primary" style="width: 120px;" v-on:click="actionSaveTier()">Update</b-button>
                                </b-col>
                                <b-col sm="8">
                                </b-col>
                              </b-row><br/>

                              <!-- <b-row>
                                <b-col sm="1" v-if="seenBtnSaveTier">
                                    <b-button variant="primary" style="width: 100px;" v-on:click="actionSaveTier()">Update</b-button>
                                </b-col>
                              </b-row><br/> -->

                              <!--b-row>
                                <b-col sm="6">
                                    <div class="form-group">
                                        <label for="input-small" >Top Up Method - Default </label>
                                        <b-form-input id="input-small"  v-model="TumDefault" :readonly="true"></b-form-input>
                                    </div>
                                </b-col>
                                <b-col sm="6">
                                    <div class="form-group">
                                        <label for="input-small" >Top Up Method - Others - 1</label>
                                        <b-form-input id="input-small"  v-model="TumOthers" :readonly="true"></b-form-input>
                                    </div>
                                </b-col>
                              </b-row -->

                               <b-row>
                                <b-col sm="12">
                                    <div class="form-group">
                                        <b-row style="text-align: center;">
                                          <b-col sm="12">
                                               <label for="input-small" >Wallet Limit</label>
                                          </b-col>
                                        </b-row>
                                        <b-row>
                                          <b-col sm="3">S$ {{ tvalue }}</b-col>
                                          <b-col sm="6">
                                              <b-progress height="20px" :max="max" class="mb-2">
                                                 <b-progress-bar variant="danger" :value="value"></b-progress-bar>
                                                 <b-progress-bar variant="success" :value="left"></b-progress-bar>
                                              </b-progress>
                                          </b-col>
                                          <b-col sm="3">S$ {{ tleft }}</b-col>
                                        </b-row>
                                        <b-row style="text-align: center;">
                                          <b-col sm="12">
                                           Maximum S$ {{ tmax }}
                                          </b-col>
                                        </b-row>
                                    </div>
                                </b-col>
                              </b-row>
                            </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="success" style="text-align:left">Status</b-button>
      </b-card-header>
      <b-collapse id="accordion-2" accordion="my-accord" role="tabpanel" v-model="collapseStates[1]">
        <b-card-body>
                          <b-container>
                              <!-- <CRow>
                                <CCol col="12" class="text-left">
                                  <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                                </CCol>
                              </CRow> -->

                              <b-row>
                                <b-col sm="6">
                                    <div class="form-group">
                                        <label for="input-small" >Created Date </label>
                                        <b-form-input id="input-small"  v-model="createDate" :readonly="true" v-dateSubstr ></b-form-input>
                                    </div>
                                </b-col>
                                <b-col sm="6">
                                    <div class="form-group">
                                        <label for="input-small" >Last Modified Date</label>
                                        <b-form-input id="input-small"  v-model="modifiedDate" :readonly="true" v-dateSubstr ></b-form-input>
                                    </div>
                                </b-col>
                              </b-row>

                              <b-row>
                                 <b-col sm="4">
                                    <div class="form-group">
                                        <label for="input-small" >Last Login Date </label>
                                        <b-form-input id="input-small"  v-model="lastloginDate" :readonly="true" v-dateSubstr></b-form-input>
                                    </div>
                                </b-col>
                                <b-col sm="4">
                                    <div class="form-group">
                                        <label for="input-small" >Last Login IP Address</label>
                                        <b-form-input id="lastLoginIp" name="lastLoginIp" :readonly="true" v-model="lastLoginIp"></b-form-input>
                                        <!--b-form-input id="input-small"  v-model="userStatus">{{ detail.userStatus }}</b-form-input>
                                        <b-form-select name="userStatus" v-model="userStatus" :options="optStatus" :disabled="!isDisabled" ></b-form-select-->
                                    </div>
                                </b-col>
                                <b-col sm="4">
                                    <div class="form-group">
                                        <label for="input-small">App Version</label>
                                        <b-form-input id="input-small"  v-model="appVersion" :readonly="true"></b-form-input>
                                    </div>
                                </b-col>
                              </b-row>

                              <b-row>
                                 <b-col sm="6">
                                    <div class="form-group">
                                        <label for="input-small" >Status </label>
                                        <b-form-select name="userStatus" v-model="userStatus" :options="optStatus" :disabled="!isDisabled" @change="optStatusChange"></b-form-select>
                                        <!--b-form-input id="reason" name="reason"  v-model="reason"></b-form-input-->
                                    </div>
                                </b-col>
                                <b-col sm="6">
                                    <div class="form-group">
                                        <label for="input-small" >Remark</label>
                                        <b-form-input id="remark" name="remark"  v-model="remark" :readonly="!isReadOnly" @keypress="keyPressURL"></b-form-input>
                                    </div>
                                </b-col>
                                <!--b-col sm="6">
                                    <div class="form-group">
                                        <label for="input-small" >Remark</label>
                                        <b-form-input id="remark" name="remark" v-model="remark" ></b-form-input>
                                    </div>
                                </b-col-->
                              </b-row>

                              <div v-if="seenOtp">
                                <b-row>
                                  <b-col sm="6">
                                      <div class="form-group">
                                          <label for="input-small" >Transact Ref.</label>
                                          <b-form-input id="transactRef" name="transactRef" v-model="transactRef" :readonly="true"></b-form-input>
                                      </div>
                                  </b-col>
                                  <b-col sm="6">
                                      <div class="form-group">
                                          <label for="input-small" >OTP </label>
                                          <b-form-input id="otp" name="otp" v-model="otp"></b-form-input>
                                      </div>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col sm="12">
                                    <CTextarea
                                      label="Reason"
                                      v-model="reason"
                                      placeholder="Type Reason Message..."
                                      description="Maximum 50 characters"
                                      maxlength="50"
                                      vertical
                                      rows="3"
                                      />
                                  </b-col>
                                </b-row>
                              </div>

                              <div class="text-center">
                                <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
                              </div>

                              <CRow>
                                <CCol col="12" class="text-left">
                                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                              </CCol>


                             </CRow>

                               <b-row>
                                 <b-col sm="6">
                                     <b-button-group>
                                          <b-button variant="warning" v-on:click="actionEdit()" v-if="seenBtnWarn">Edit</b-button>
                                          <b-button v-on:click="actionCancel()" v-if="seenBtnCcel">Cancel</b-button>
                                          <b-button variant="success" v-on:click="actionSave()">Save</b-button>
                                     </b-button-group>

                                </b-col>
                                <b-col sm="6">
                                    <span>
                                        <b-button variant="danger" v-on:click="ResetPin()">Reset PIN</b-button>
                                    </span>

                                </b-col>
                              </b-row>

                          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-3 variant="success" style="text-align:left">KYC Details</b-button>
      </b-card-header>
      <b-collapse id="accordion-3" accordion="my-accord" role="tabpanel" v-model="collapseStates[2]">
        <b-card-body>
                          <b-container>
                              <b-row>
                                <b-col sm="4">
                                    <div class="form-group">
                                        <label for="input-small" >First Name </label>
                                        <b-form-input id="input-small"  v-model="firstName" :readonly="true">{{ firstName }}</b-form-input>
                                    </div>
                                </b-col>
                                <b-col sm="4">
                                    <div class="form-group">
                                        <label for="input-small" >Last Name</label>
                                        <b-form-input id="input-small"  v-model="lastName" :readonly="true">{{ lastName }}</b-form-input>
                                    </div>
                                </b-col>
                                <b-col sm="4">
                                    <div class="form-group">
                                        <label for="input-small" >Date of Birth</label>
                                        <b-form-input id="input-small"  v-model="dateOfBirth" :readonly="true">{{ dateOfBirth }}</b-form-input>
                                    </div>
                                </b-col>
                              </b-row>

                              <b-row>
                                <b-col sm="4">
                                    <div class="form-group">
                                        <label for="input-small" >Gender </label>
                                        <b-form-input id="input-small"  v-model="gender" :readonly="true">{{ gender }}</b-form-input>
                                    </div>
                                </b-col>
                                <b-col sm="4">
                                    <div class="form-group">
                                        <label for="input-small" >Nationality</label>
                                        <b-form-input id="input-small"  v-model="nationality" :readonly="true">{{ nationality }}</b-form-input>
                                    </div>
                                </b-col>
                                <b-col sm="4">
                                    <div class="form-group">
                                        <label for="input-small" >Occupation</label>
                                        <b-form-input id="input-small"  v-model="occupation" :readonly="true">{{ occupation }}</b-form-input>
                                    </div>
                                </b-col>
                              </b-row>

                              <b-row>
                                <b-col sm="4">
                                    <div class="form-group">
                                        <label for="input-small" >ID/Passport No. </label>
                                        <b-form-input id="input-small"  v-model="icPassport" :readonly="true">{{ icPassport }}</b-form-input>
                                    </div>
                                </b-col>
                                <b-col sm="4">
                                    <div class="form-group">
                                        <label for="input-small" >Country of Residence</label>
                                        <b-form-input id="input-small"  v-model="countryOfResidence" :readonly="true">{{ countryOfResidence }}</b-form-input>
                                    </div>
                                </b-col>
                                <b-col sm="2">
                                    <div class="form-group">
                                    <label for="input-small" >City</label>
                                    <b-form-input id="input-small"  v-model="city" :readonly="true">{{ city }}</b-form-input>
                                    </div>
                                </b-col>
                                <b-col sm="2">
                                    <div class="form-group">
                                    <label for="input-small" >Portal</label>
                                    <b-form-input id="input-small"  v-model="postcode" :readonly="true">{{ postcode }}</b-form-input>
                                    </div>
                                </b-col>
                              </b-row>

                              <b-row>
                                <b-col sm="12">
                                    <div class="form-group">
                                        <label for="input-small" >Address</label>
                                        <b-form-input id="input-small"  v-model="address" :readonly="true">{{ address }}</b-form-input>
                                    </div>
                                </b-col>
                                <!--b-col sm="4">
                                    <div class="form-group">
                                        <label for="input-small" >Status</label>
                                        <b-form-input id="input-small"  v-model="userStatus" :readonly="true">{{ userStatus }}</b-form-input>
                                    </div>
                                </b-col-->
                              </b-row>
                          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-4 variant="success" style="text-align:left">KYC Images</b-button>
      </b-card-header>
      <b-collapse id="accordion-4" accordion="my-accord" role="tabpanel" v-model="collapseStates[3]">
        <b-card-body>
                          <b-container>
                              <b-row>
                                <b-col sm="3">
                                    <label for="input-small" >ID/Passport Image (Front) </label>
                                </b-col>
                                <b-col sm="3">
                                    <label for="input-small" >ID/Passport Image (Back)</label>
                                </b-col>
                                <b-col sm="3">
                                    <label for="input-small" >Face Verification</label>
                                </b-col>
                                <b-col sm="3">
                                    <label for="input-small" >Address</label>
                                </b-col>
                              </b-row>

                              <b-row>
                                <b-col sm="3">
                                    <b-img @error="onImageLoadFailure($event)" v-bind="imgProps" :src="'data:image/png;base64,'+ frontImgPasswd " alt=""></b-img>
                                </b-col>
                                <b-col sm="3">
                                    <b-img @error="onImageLoadFailure($event)" v-bind="imgProps" :src="'data:image/png;base64,'+ backImgPasswd " alt=""></b-img>
                                </b-col>
                                <b-col sm="3">
                                    <b-img @error="onImageLoadFailure($event)" v-bind="imgProps" :src="'data:image/png;base64,'+ faceImgVerify " alt=""></b-img>
                                </b-col>
                                <b-col sm="3">
                                    <b-img @error="onImageLoadFailure($event)" v-bind="imgProps" :src="'data:image/png;base64,'+ addressImg " alt=""></b-img>
                                    <!--b-img v-bind="mainProps" rounded alt="Rounded image"></b-img-->
                                </b-col>
                              </b-row>
                          </b-container>

        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-5 variant="success" style="text-align:left">Login & Device History</b-button>
      </b-card-header>
      <b-collapse id="accordion-5" accordion="my-accord" role="tabpanel" v-model="collapseStates[4]">
        <b-card-body>
          <b-container>
            <b-row>
              <b-col sm="4">
                <div class="form-group">
                  <label class="font-weight-bold">Cooling Off Time</label>
                  <div v-if="!!coolOffStatus">{{ coolOffStatus.timeToCollOffSeconds/60 | formatNum }} minute</div>
                  <div v-else>{{ 0 }} minute</div>
                </div>
              </b-col>
              <b-col sm="12" class="mt-4">
                <b-row align-v="center">
                  <b-col>
                    <div class="form-group">
                      <span class="font-weight-bold">IP History</span>
                    </div>
                  </b-col>
                  <b-col sm="3" >
                    <div class="form-group" >
                      <b-form-input v-model="filterIpTable" placeholder="Type to Search"></b-form-input>
                    </div>
                  </b-col>
                </b-row>
                <div id="ip-table-container" class="table-responsive table">
                  <b-table striped hover :items="ipHistories" :fields="ipTableHeaders" sort-by="createDate" :sort-desc="true" :current-page="currentPageIpTable" :per-page="perPageIpTable" :filter="filterIpTable" @filtered="(filteredItems) => onFiltered(filteredItems, 'ipTable')">
                  </b-table>
                  <b-pagination size="md" :total-rows="totalRowIpHistory" :per-page="perPageIpTable" v-model="currentPageIpTable" />
                </div>
              </b-col>
              <b-col sm="12" class="mt-4">
                <b-row align-v="center">
                  <b-col>
                    <div class="form-group">
                      <span class="font-weight-bold">Device History</span>
                    </div>
                  </b-col>
                  <b-col sm="3" >
                    <div class="form-group" >
                      <b-form-input v-model="filterDeviceTable" placeholder="Type to Search"></b-form-input>
                    </div>
                  </b-col>
                </b-row>
                <div id="device-table-container" class="table-responsive table">
                  <b-table striped hover :items="deviceHistories" :fields="deviceHistoryTableHeaders" sort-by="createDate" :sort-desc="true" :current-page="currentPageDeviceTable" :per-page="perPageDeviceTable" :filter="filterDeviceTable" @filtered="(filteredItems) => onFiltered(filteredItems, 'deviceTable')">
                  </b-table>
                  <b-pagination size="md" :total-rows="totalRowDeviceHistory" :per-page="perPageDeviceTable" v-model="currentPageDeviceTable" />
                </div>
              </b-col>
              <b-col sm="12" class="mt-4">
                <b-row align-v="center">
                  <b-col>
                    <div class="form-group">
                      <span class="font-weight-bold">Payee</span>
                    </div>
                  </b-col>
                  <b-col sm="3" >
                    <div class="form-group" >
                      <b-form-input v-model="filterPayeeTable" @change="currentPagePayeeTable = 1" placeholder="Type to Search"></b-form-input>
                    </div>
                  </b-col>
                </b-row>
                <div id="payee-table-container" class="table-responsive table">
                  <b-table striped hover :items="payeeHistories" :fields="payeeHistoryTableHeaders" sort-by="createDate" :sort-desc="true" :current-page="currentPagePayeeTable" :per-page="perPagePayeeTable" :filter="filterPayeeTable" @filtered="(filteredItems) => onFiltered(filteredItems, 'payeeTable')">
                  </b-table>
                  <b-pagination size="md" :total-rows="totalRowPayee" :per-page="perPagePayeeTable" v-model="currentPagePayeeTable" />
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>


  </div>
  </div>
</template>

<script>
import AuthService from '@/api/AuthService.js';

const constIpTableHeaders = [
        {
          name : 'ipAddress',
          key: 'ipAddress',
          label : 'IP Address',
          sortable: true
        },
        {
          name : 'lastLogin',
          key: 'lastLogin',
          label : 'Last Login',
          sortable: true
        },
        {
          name : 'initialLogin',
          key: 'initialLogin',
          label : 'Initial Login',
          sortable: true
        }
      ];
const constDeviceHistoryTableHeaders = [
        {
          name : 'deviceId',
          key: 'deviceId',
          label : 'Device ID',
          sortable: true
        },
        {
          name : 'deviceName',
          key: 'deviceName',
          label : 'Device Name',
          sortable: true
        },
        {
          name : 'deviceBrand',
          key: 'deviceBrand',
          label : 'Brand',
          sortable: true
        },
        {
          name : 'deviceSystem',
          key: 'deviceSystem',
          label : 'System',
          sortable: true
        },
        {
          name : 'deviceModel',
          key: 'deviceModel',
          label : 'Model',
          sortable: true
        },
        {
          name : 'deviceVersion',
          key: 'deviceVersion',
          label : 'Version',
          sortable: true
        },
        {
          name : 'lastLogin',
          key: 'lastLogin',
          label : 'Last Login',
          sortable: true
        },
      ];

const constPayeeHistoryTableHeaders = [
  {
    name : 'accountName',
    key: 'accountName',
    label : 'Account Name',
    sortable: true
  },
  {
    name : 'coolOffTime',
    key: 'coolOffTime',
    label : 'Cooling Off Time',
    sortable: true
  },
]

export default {
    name: 'account',
    data () {
      return {
        spinner : false,
        cbEditTier:false,
        isDisabledTier:false,
        seenBtnSaveTier:false,
        optUsrTier:[],
        appVersion:'None',
        value : 0,
        max : 0,
        left : 0,
        tvalue:'',
        tmax:'',
        tleft:'',
        userId: this.$route.params.id,
        issadm:false,
        collapseStates: [true, false, false, false, false],
        frontImgPasswd: '',
        backImgPasswd: '',
        faceImgVerify: '',
        addressImg:'',
        userName:'',
        displayName:'',
        firstName:'',
        lastName:'',
        gender:'',
        nationality:'',
        occupation:'',
        icPassport:'',
        countryOfResidence:'',
        address:'',
        city:'',
        postcode:'',
        dateOfBirth:'',
        emailAddress:'',
        phoneNumber:'',
        fullPhoneNumber:'',
        userGroup:'',
        groupUniqueNumber:'',
        preflang:'',
        userTier:'',
        previousTier:'',
        tierAchievedDate:'',
        otp:'',
        transactRef:'',
        reason:'',
        seenOtp:false,
        createDate:'',
        modifiedDate:'',
        lastLoginIp:'',
        userStatus:'',
        remark:'',
        mCoinBalance:'',
        TumDefault:'',
        TumOthers:'',
        lastloginDate:'',
        defOptSts:'',
        defOptCurr:'',
        isReadOnly : false,
        isDisabled : false,
        seenBtnCcel: false,
        seenBtnWarn : true,
        imgProps: {  width: 200, height: 300  },
        detail:[],
        images:[],
        optStatus:[
            { value: 'ACTIVE', text: 'ACTIVE'},
            { value: 'INACTIVE', text: 'INACTIVE'},
            { value: 'BLOCKED', text: 'BLOCKED'}
        ],
        seen:false,
        message:[],
        msg :'',
        color:'',
        deviceHistories: [],
        deviceHistoryTableHeaders: constDeviceHistoryTableHeaders,
        ipHistories: [],
        ipTableHeaders: constIpTableHeaders,
        payeeHistories: [],
        payeeHistoryTableHeaders: constPayeeHistoryTableHeaders,
        totalRowIpHistory: 0,
        totalRowDeviceHistory: 0,
        totalRowPayee: 0,

        currentPageIpTable: 1,
        perPageIpTable: 5,
        filterIpTable: null,

        currentPageDeviceTable: 1,
        perPageDeviceTable: 5,
        filterDeviceTable: null,

        currentPagePayeeTable: 1,
        perPagePayeeTable: 5,
        filterPayeeTable: null,

        coolOffStatus: null,
      }
    },
    directives: {
        dateSubstr: {
            update(el) {
                var temp = el.value.toString().split(".");
                el.value =  temp[0].replace("T"," ");
            },
        },
    },
    created () {
       this.getUserDetail(this.$route.params.id);
       this.fetchKYCImg(this.$route.params.id);
       this.getWalletLimit(this.$route.params.id);
       this.getConfigList();

    },
    computed: {
       /*isDisabled() { return true; }*/
    },
    filters:{
      formatNum(value){
        if(!value) return 0
        return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
      }
    },
    methods: {
      onFiltered(filteredItems, table) {
        switch (table) {
          case 'ipTable':
            this.totalRowIpHistory = filteredItems.length;
            this.currentPageIpTable = 1;
            break;
          case 'deviceTable':
            this.totalRowDeviceHistory = filteredItems.length;
            this.currentPageDeviceTable = 1;
            break;
          case 'payeeTable':
            this.totalRowPayee = filteredItems.length;
            this.currentPagePayeeTable = 1;
            break;
        }
      },
      onchangeTier(){
        this.seenBtnSaveTier = true;
      },

      onModifyClick(){
          if(this.cbEditTier){
             this.isDisabledTier = true;
          } else {
             this.isDisabledTier = false;
          }
          this.seenBtnSaveTier = true;
       },

       actionSaveTier(){
          let params = {};
          params['manualUserTier'] = this.cbEditTier;
          params['userTier']= this.userTier;

          this.updateWalletUser(this.userId, params);
       },

       onImageLoadFailure (event) {
         event.target.src = 'img/default.png'
       },

       actionEdit(){
           this.isDisabled = true;
           //this.isReadOnly= true;
           this.seenBtnCcel = true;
           this.seenBtnWarn = false;

       },

       actionCancel(){

           this.isDisabled = false;
           this.isReadOnly= false;
           this.seenBtnCcel = false;
           this.seenBtnWarn = true;
           this.seenOtp = false;
           this.resetOtp();
       },

       ResetPin(){
           var phoneNum = this.fullPhoneNumber;
           var userId = this.$route.params.id;
           //var roles = localStorage.getItem('roles');
           //var eachrole = roles.split(",");

           if (confirm('Are you sure you want to Reset Pin User ID  '+ userId +' ? ')) {
                  AuthService.checkForgotPin(phoneNum).then((resp)=>{
                    // eslint-disable-next-line no-prototype-builtins
                      if(resp.status === 200 && resp.hasOwnProperty('data') === true && resp.data.statusCode == "S0000"){
                        AuthService.ReseteWalletPin(phoneNum)
                             .then((resp) => {
                                if(resp.message==="SUCCESS"){
                                  this.sendResetPinNotification(userId,phoneNum);
                                } else {
                                  this.msg   =  !resp.message ? "Reset PIN Failed!" : resp.message;
                                  this.color = 'danger'; this.seen  = true;
                                }
                                setTimeout( () =>  this.seen=false , 10000);
                        }).catch((e) => { console.log(e); });
                      }else{
                          this.msg   =  !resp.message ? "User need to do reset pin first" : resp.message;
                          this.color = 'danger'; this.seen  = true;
                      }
                  }).catch((e) => { console.log(e); });
           }
       },

       resetOtp(){
          this.reason = "";
          this.otp = "";
          this.transactRef = "";
       },

       sendResetPinNotification: function(userId,phoneNum) {
          AuthService.sendResetPinNotification(userId).then(resp => {
                if(resp.message==="Success"){
                    var msg = "Reset PIN User ID "+ userId +" Success"
                    this.clearResetPin(phoneNum,msg);
                }
          }, error => {
            console.log(error);
            this.spinner = false;
          });
       },

       clearResetPin: function(mobileNumber,msg) {
          AuthService.clearResetPin(mobileNumber).then(resp => {
                this.msg   = (resp.statusCode === "S0000") ? msg : resp.data.message;
                this.color = (resp.statusCode === "S0000") ? 'success' : 'warning';
                this.seen  = true;
          }, error => {
            this.spinner = false;
            console.log(error);
          });
       },

       actionSave() {
            this.spinner = true;
            var status   = this.userStatus;
            var userId   = this.$route.params.dbid;
            var userName = this.userName;
          switch(status) {
            case "ACTIVE":
              var sts = "activate"; break;
            case "INACTIVE":
              var sts = "inactivate"; break;
            default:
              var sts = "block";
          }

          if(sts === "activate" && !this.otp){
             this.genOtpForActivateUser();
          } else if(sts === "activate" && this.otp){
            let params = {};
            params["message"]   = this.reason;
            params["otp"]       = this.otp;
            params["transRef"]  = this.transactRef;
            params["userId"]    = userId;
            this.validateUserByOTP(params,sts);
          } else {
            this.updateStsOpayUser(userId,userName,sts);
          }

       },

       validateUserByOTP: function(params,sts) {
          AuthService.validateUserByOTP(params).then(resp => {
            if(resp.statusCode=='S0000'){
                //var userId   = this.$route.params.dbid;
                var userName = this.userName;
                this.updateStsAuthUser(userName,sts);
            } else {
               this.msg   = resp.data.message;
               this.color = 'warning';
               this.seen = true; this.spinner =false;
               setTimeout( () =>  this.seen=false , 5000);
            }
           }, error => {
              console.log('error');
          });
       },

       genOtpForActivateUser: function() {
          AuthService.genOtpForActivateUser().then(resp => { //console.log(resp);
            if(resp.statusCode=='S0000'){
               this.transactRef = resp.message;
               this.seenOtp = true;
               this.msg     = "An OTP Code has been sent to your Approver. Please request code from Approver!";
               this.color   = 'info';
            } else {
               this.msg   = resp.message;
               this.color = 'warning';
            }
            this.seen = true; this.spinner =false;
            setTimeout( () =>  this.seen=false , 5000);
           }, error => {
              console.log('error');
          });
       },

       updateStsOpayUser: function(id,userName, status) {
         let remarks = encodeURIComponent(this.remark);
         if(remarks === "" && status !== "activate") {
             this.msg   = "Remarks are required to inactivate or block user";
             this.color = 'danger';
             this.spinner = false;
             this.seen = true;
             setTimeout(() => this.seen = false, 5000);
         } else {
             AuthService.updateStsOpayUser(id, status, remarks).then(resp => {
               if (resp.statusCode === "S0000") {
                 var sts = (status === "inactivate") ? "deactivate" : status;
                 AuthService.updateStsAuthUser(userName, sts)
                     .then((res) => {
                       if (res.status === "OK") {
                         this.isDisabled = false;
                         this.isReadOnly = false;
                         this.seenBtnCcel = false;
                         this.seenBtnWarn = true;
                         this.seenOtp = false;
                         this.resetOtp();
                         this.msg = !res.message ? "Update Status User Success !" : res.message;
                         this.color = 'info';
                       } else {
                         this.msg = !res.message ? "Update Status User Failed !" : res.message;
                         this.color = 'danger';
                       }
                       this.spinner = false;
                     }).catch((e) => {
                   console.log(e);
                 });
               } else {
                 this.msg = !resp.message ? "Update Status User Failed !" : resp.message;
                 this.color = 'danger';
               }

               this.seen = true;
               setTimeout(() => this.seen = false, 5000);

             }, error => {
               this.spinner = false;
               console.log(error);
             });
         }
       },

       updateStsAuthUser: function(userName,status) {
          AuthService.updateStsAuthUser(userName,status).then(resp => {

            if(resp.status=="OK"){
                this.isDisabled = false;  this.isReadOnly  = false;
                this.seenBtnCcel = false; this.seenBtnWarn = true;  this.seenOtp = false; this.resetOtp();
                this.msg   = !resp.message ? "Update Status User Success !" : resp.message;
                this.color = 'info';
            } else {
                this.msg   = !resp.message ? "Update Status User Failed !" : resp.message;
                this.color = 'danger';
            }

            this.seen  = true;
            setTimeout( () =>  this.seen=false , 5000);
            this.spinner = false;

          }, error => {
            this.spinner = false;
          });
       },

       actionVerify(){
            var emailAddress = this.emailAddress;
            alert('data : ' +  emailAddress );
       },

      keyPressURL(evt){
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        var keyCode = key;
        key = String.fromCharCode(key);
        if (key.length == 0) return;
        var regex = /^[A-Za-z0-9-, \b]+$/;
        if (keyCode == 188 || keyCode == 190) {
          return;
        } else {
          if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
          }
        }
      },

      optStatusChange(value) {
          if(value == 'ACTIVE') {
            this.isReadOnly = false;
          } else {
            this.isReadOnly = true;
          }
      },

      getWalletLimit: function(id) {
          AuthService.getWalletLimit(id).then(resp => {

              var data   = resp.payload;
              this.value = parseFloat(data.currentValue);
              this.max   = parseFloat(data.limitValue);
              let left   = parseFloat(data.limitValue) - parseFloat(data.currentValue);
              this.left   = parseFloat(left);

              this.tvalue = data.currentValue.toLocaleString();
              this.tmax   = data.limitValue.toLocaleString();
              this.tleft  = left.toLocaleString();

          }, error => {
            this.spinner = false;
          });
       },

       fetchKYCImg: function(id) {
          AuthService.getKYCImages(id).then(resp => {
          this.images = resp;  //console.log(resp);


          this.frontImgPasswd = resp.icFrontImageData;
          this.backImgPasswd = resp.icBackImageData;
          this.faceImgVerify = resp.icSelfieImageData;
          this.addressImg = resp.billingProofImageData;
          }, error => {
            this.loading = false;
          });
       },

       getUserDetail: function(id) {
          AuthService.getUserDetail(id).then(resp => {
          this.detail = resp;
          this.displayName = resp.displayName;
          this.firstName = resp.firstName;
          this.lastName = resp.lastName;
          this.gender = resp.gender;
          this.nationality = resp.nationality;
          this.occupation = resp.occupation;
          this.icPassport = resp.icPassport;
          this.countryOfResidence = resp.countryOfResidence;
          this.address = resp.address;
          this.city = resp.city;
          this.postcode = resp.postcode;
          this.dateOfBirth = resp.dateOfBirth;
          this.emailAddress = resp.emailAddress;
          this.phoneNumber = !!resp.mobileNumber ? resp.mobileNumber.countryCode+' '+resp.mobileNumber.number : '';
          this.fullPhoneNumber = !!resp.mobileNumber ? resp.mobileNumber.countryCode+resp.mobileNumber.number : '';
          this.userGroup = !!resp.defaultUserGroup ? resp.defaultUserGroup.name : '';
          this.groupUniqueNumber = !!resp.defaultUserGroup ? resp.defaultUserGroup.groupUniqueNumber : '';
          this.userTier  = resp.userTier;
          this.preflang  = resp.preferredLanguage;
          this.previousTier = resp.previousTier;
          this.tierAchievedDate = resp.tierAchievedDate;

          this.createDate = resp.createDate;
          this.modifiedDate = resp.lastUpdate;
          this.userStatus = resp.userStatus;
          if(resp.userStatus !== "active") this.isReadOnly = false;

          var roles = localStorage.getItem('roles');
          var eachrole = roles.split(",");
          this.issadm = (eachrole.includes("ROLE_ADMIN_ACTIVATE")) ? true : false;
          //this.issadm = (localStorage.getItem('sa')==="true") ? true : false;
          this.lastloginDate = resp.lastLogin;
          this.lastLoginIp = resp.lastLoginIp;
          this.mCoinBalance = resp.mcoinsAmount;
          this.appVersion = resp.appVersion;
          this.userName = resp.userName;
          this.ipHistories = !!resp.ipHistory ? resp.ipHistory : [];
          this.deviceHistories = !!resp.deviceHistory ? resp.deviceHistory : [];
          this.payeeHistories = !!resp.recipients ? resp.recipients.map(recipient => ({...recipient, coolOffTime: recipient.coolOffTime || "-"})) : [];
          this.coolOffStatus = resp.coolOffStatus;
          this.totalRowIpHistory = this.ipHistories.length;
          this.totalRowDeviceHistory = this.deviceHistories.length;
          this.totalRowPayee = this.payeeHistories.length;

          if(resp.manualUserTier){
            this.cbEditTier = true;
            this.isDisabledTier = true;
          }

          }, error => {
            this.loading = false;
          });
       },

       getConfigList: function () {
         AuthService.getConfigList().then(resp => {  //console.log(resp);

            var usrTLType = resp.userTierList; var userTLSrc = [];
            for (var key in usrTLType) {
               var usrTLName = usrTLType[key];
               userTLSrc.push({ value: usrTLName, text: usrTLName });
            }
            this.optUsrTier = userTLSrc;

         }, error => {
            console.log('error');
         });
       },

       updateWalletUser: function(userIdentify,params) {
          AuthService.updateWalletUser(userIdentify,params).then(resp => { console.log(resp);

            if(resp.message === "Success"){
              this.msg   = "Update User Tier Successfully ";
              this.color = 'info';
              this.seenBtnSaveTier = false;
              if(!params.manualUserTier){
                this.isDisabledTier = false;
              }
              this.getUserDetail(this.userId);
            } else {
              this.msg   = !resp.message ? "Update User Tier Failed " : resp.message;
              this.color = 'danger';
            }

            this.seen  = true;
            setTimeout( () =>  this.seen=false , 5000);

          }, error => {
            this.spinner = false;
          });
       },

    },
 };

</script>
