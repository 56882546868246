<template>
  <div class="accordion" role="tablist">
    <CRow>
      <CCol col="12" class="text-left">
        <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
      </CCol>
    </CRow>
    <div v-for="(value, key) in wallets">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle="'accordion' + key" variant="success" style="text-align:left"> {{ value.walletGroup.name }} </b-button>
        </b-card-header>
        <b-collapse :id="'accordion' + key" visible accordion="my-accordion" role="tabpanel">
          <b-card-body>



                <div class="table-responsive">
                  <b-table striped hover  :items="value.items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" sort-by="expireDate" :sort-desc="true" >

                    <template v-slot:cell(createDate)="{ item }">
                      {{ item.createDate | dateWithTime }}
                    </template>
                    <template v-slot:cell(expireDate)="{ item }">
                      {{ item.expireDate | dateWithTime }}
                    </template>
                    <template v-slot:cell(feature)="{ item }">
                      {{ item.feature | replaceCharacter }}
                    </template>
                    <template v-slot:cell(amount)="{ item }">
                      {{ item.amount | numberWithCommas }}
                    </template>
                    <template v-slot:cell(actions)="{ item }">

                      <b-dropdown variant="info" text="Actions" size="md">
                        <router-link to="#" exact v-b-modal="'modal-' + item.id" tag="b-dropdown-item" > Detail Utilization</router-link>
                        <router-link :to='"/eWallet/details/" + userId + "/" + dbId + "/earmark/" + item.id + "/edit"'   tag="b-dropdown-item" > Update Amount </router-link>
                        <router-link :to='"/eWallet/details/" + userId + "/" + dbId + "/earmark/" + item.id + "/adjust"' tag="b-dropdown-item" > Add Amount </router-link>
                        <b-dropdown-divider></b-dropdown-divider>
                        <router-link to="#" exact v-on:click.native="deleteEarmark(item.id)" tag="b-dropdown-item" > Remove </router-link>
                      </b-dropdown>
                      <b-modal :id="'modal-'+item.id" title="Detail Utilization Earmark">
                        <div class="my-2">Utilization {{ item.feature | replaceCharacter }} | {{ item.currency }} :</div>
                        <b-table striped hover responsive :items="item.utilization" :fields="fieldsModal"  sort-by="lastUpdate" :sort-desc="true">
                          <template v-slot:cell(createDate)="{ item }">
                            {{ item.createDate | dateWithTime }}
                          </template>
                          <template v-slot:cell(lastUpdate)="{ item }">
                            {{ item.lastUpdate | dateWithTime }}
                          </template>
                          <template v-slot:cell(amount)="{ item }">
                            {{ item.amount | numberWithCommas }}
                          </template>
                        </b-table>
                      </b-modal>
                    </template>

                  </b-table>
                </div>

                <div>
                  <b-row>
                    <b-col sm="3">
                      <b-pagination size="md" :total-rows="items.length" :per-page="perPage" v-model="currentPage" />
                    </b-col>
                    <b-col sm="9">
                      <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
                      </b-form-select>
                    </b-col>
                  </b-row>
                </div>
            </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import AuthService from '@/api/AuthService.js';

export default {
  name: "earmark",
  data() {
    return {
      items:[],
      fields: [
        {
          name:'createDate',
          key: 'createDate',
          label : 'Date / Time',
          sortable: true
        },
        {
          name:'expireDate',
          key: 'expireDate',
          label : 'Expired',
          sortable: true
        },
        {
          key: 'feature',
          label : 'Feature',
          sortable: true
        },
        {
          key: 'currency',
          label : 'Currency',
          sortable: true
        },
        {
          key: 'amount',
          label : 'Amount',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Actions'
        }
      ],
      fieldsModal: [
        {
          key: 'transactionRef',
          label: 'Ref',
          sortable: true
        },
        {
          key: 'amount',
          label : 'Amount',
          sortable: true
        },
        {
          name:'createDate',
          key: 'createDate',
          label : 'Date / Time',
          sortable: true
        },
        {
          name:'lastUpdate',
          key: 'lastUpdate',
          label : 'Last Updated',
          sortable: true
        }
      ],
      currentPage: 1,
      perPage: 10,
      filter: null,
      status:'ALL',
      optStatus:['COMPLETED','CONSUMED','CANCELED','EXPIRED','ALL'],
      seen:false,
      msg :'',
      color:'',
      userId:'',
      dbId:'',
      wallets:[],
      detail:[]
    };
  },

  filters: {
    upper(value){
      if(!value) return ''
      value = value.toString()
      return value.toUpperCase()
    },
    lower(value){
      if(!value) return ''
      value = value.toString()
      return value.toLowerCase()
    },
    replaceCharacter(value) {
      if(!value) return ''
      value = value.toString()
      value = value.replace('_', ' ')
      value = value.replace('_', ' ')
      return value;
    },
    dateSubstr(value){
      if(!value) return ''
      value = value.toString()
      return value.substring(0,10)
    },

    IdSubstr(value){
      if(!value) return ''
      value = value.toString()
      return value.substring(0,5) + '...'
    },
    dateWithTime(value){
      if(!value) return ''
      var temp = value.toString().split(".");
      value = temp[0].replace("T"," ");
      return value;
    },
    nullnull(value){
      if(!value) return '';
      if(value === 'null null') return '';
      return value;
    },
    numberWithCommas(x) {
      x = parseFloat(x).toFixed(2);
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },

  created () {
    this.dbId = this.$route.params.dbid;
    this.userId = this.$route.params.id;
    this.getUserDetail(this.$route.params.id);
  },

  methods: {

    actCancelUserPoint(id){

      if (confirm('Are you sure you want to Cancel User Point  ? ')) {
        this.cancelUserPoint(id);
      }

    },

    onChangeStatus(){
      this.retrivePoints();
    },

    retrivePoints(){
      let params = {};
      params['id']     = this.$route.params.id;
      params['status'] = this.status;
      this.getUserPoints(params);
    },

    deleteEarmark(id) {
      this.spinner = true;
      AuthService.earMarkDelete(id).then(resp => {
          console.log(resp);
          this.loading = false;
          this.msg = resp.message;
          this.color = (resp.statusCode !== "S0000") ? 'warning' : 'success';
          this.seen = true;
          this.spinner = false;
          this.getUserDetail(this.userId);
        setTimeout(() => this.seen = false, 5000);
      }, error => {
          console.log(error);
          this.spinner = false;
      });
    },

    getUserDetail: function(id) {
      let dis    = this;
      let toDate = new Date();
      AuthService.getUserDetail(id).then(resp => {
        this.detail = resp;
        console.log(resp.wallets);
        resp.wallets.map(function(values){
            console.log(values.walletId);
            let listWallets = [];
            AuthService.earMarkByWalletId(values.walletId).then(resp => {
                //console.log(resp);
                let itemsWallet = [];
                let exprWallet  = [];
                let availWallet = [];
                if(resp.payload.length > 0) {
                  resp.payload.forEach((values) => {
                     let exprDate = new Date(values.expireDate);
                     let utilized = [];
                     AuthService.earMarkUtilizationById(values.id).then(respUtil => {
                       respUtil.payload.forEach( (valueUtil) => {
                         utilized.push(valueUtil)
                       });
                     }, error => {
                        console.log(error)
                     });
                     if(toDate <= exprDate && values.amount > 0) {
                       values.utilization = utilized;
                       itemsWallet.push(values);
                       availWallet.push(values);
                     } else {
                       exprWallet.push(values);
                     }
                  });
                }
                values.items = itemsWallet;
                listWallets.push(values);

                console.log(values);
            }, error => {
                console.log(error);
            });
            dis.wallets = listWallets;
        });
        this.wallets.sort(function compareSecondColumn(a, b) {
          if (a.walletGroup.name === b.walletGroup.name) {
            return 0;
          }
          else {
            return (a.walletGroup.name < b.walletGroup.name) ? -1 : 1;
          }
        })
      }, error => {
        this.loading = false;
        console.log(error);
      });
    },

    cancelUserPoint: function(id) {
      AuthService.cancelUserPoint(id).then(resp => { //console.log(resp);

        if(resp==="S0000"){
          this.retrivePoints();
          this.msg   = "Cancel User Point Success!";
          this.color = 'success'; this.seen  = true;

        } else {
          this.msg   =  !resp.data.message ? "Action Cancel Failed!" : resp.data.message;
          this.color = 'danger'; this.seen  = true;
        }
        setTimeout( () =>  this.seen=false , 5000);

      }, error => {
        this.loading = false;
        console.log(error);
      });
    },

    getUserPoints: function(params) {
      AuthService.getReferral(params).then(resp => {   //console.log(resp);
        this.items = resp;
      }, error => {
        this.loading = false;
        console.log(error);
      });
    },

    getConfigList: function() {
      AuthService.getConfigList().then(resp => {  //console.log(resp);
        //this.optStatus = resp.userStatus;
      }, error => {
        console.log(error);
      });
    },

  }

};

</script>
